import React, {useMemo, useState} from "react";
import {Button, Container, Grid, Header, Icon, Message, Popup, Segment, Tab, Table} from "semantic-ui-react";
import TimeMachineRundownCMSOverrideEditorTable from "./TimeMachineRundownCMSOverrideEditorTable";
import ContingentButton from "../../ContingentButton";

export default function TimeMachineRundownCMSOverrideEditorView(props) {
    const [franchiseId, setFranchiseId] = useState("");
    const [seriesId, setSeriesId] = useState("");
    const [seasonId, setSeasonId] = useState("");
    const [titleId, setTitleId] = useState("");
    const [showId, setShowId] = useState("");
    const [instance, setInstance] = useState("");
    const [originalFranchiseOverrides, setOriginalFranchiseOverrides] = useState([]);
    const [originalSeriesOverrides, setOriginalSeriesOverrides] = useState([]);
    const [originalSeasonOverrides, setOriginalSeasonOverrides] = useState([]);
    const [originalTitleOverrides, setOriginalTitleOverrides] = useState([]);
    const [originalShowOverrides, setOriginalShowOverrides] = useState([]);
    const [franchiseOverrides, setFranchiseOverrides] = useState([]);
    const [seriesOverrides, setSeriesOverrides] = useState([]);
    const [seasonOverrides, setSeasonOverrides] = useState([]);
    const [titleOverrides, setTitleOverrides] = useState([]);
    const [showOverrides, setShowOverrides] = useState([]);
    const [addFranchiseOverrideDisabled, setAddFranchiseOverrideDisabled] = useState(false);
    const [addSeriesOverrideDisabled, setAddSeriesOverrideDisabled] = useState(false);
    const [addSeasonOverrideDisabled, setAddSeasonOverrideDisabled] = useState(false);
    const [addTitleOverrideDisabled, setAddTitleOverrideDisabled] = useState(false);
    const [addShowOverrideDisabled, setAddShowOverrideDisabled] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [showWithOverridesApplied, setShowWithOverridesApplied] = useState(null);
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    useMemo(function onSelectedShowUpdate() {
        if (props.selectedShow) {
            setFranchiseId(props.selectedShow.franchise_id || "");
            setSeriesId(props.selectedShow.series_id || "");
            setSeasonId(props.selectedShow.season_id || "");
            setTitleId(props.selectedShow.title_id || "");
            setShowId(props.selectedShow.show_id || "");
            setInstance(props.selectedShow.feed_id || "");
            setShowWithOverridesApplied(Object.assign({}, props.selectedShow));
        }
    }, [JSON.stringify(props.selectedShow)]);

    useMemo(function updateSaveDisabled() {
        setSaveDisabled(addFranchiseOverrideDisabled || addSeriesOverrideDisabled || addTitleOverrideDisabled || addShowOverrideDisabled || !unsavedChanges);
    }, [addFranchiseOverrideDisabled, addSeriesOverrideDisabled, addTitleOverrideDisabled, addShowOverrideDisabled, unsavedChanges]);

    useMemo(function onOverrideUpdate() {
        const updatedFranchiseOverrides = [];
        const updatedSeriesOverrides = [];
        const updatedSeasonOverrides = [];
        const updatedTitleOverrides = [];
        const updatedShowOverrides = [];
        if (props.overrides.hasOwnProperty("franchise")) {
            for (const [key, value] of Object.entries(props.overrides.franchise)) {
                updatedFranchiseOverrides.push({key, value});
            }
        }

        if (props.overrides.hasOwnProperty("series")) {
            for (const [key, value] of Object.entries(props.overrides.series)) {
                updatedSeriesOverrides.push({key, value});
            }
        }

        if (props.overrides.hasOwnProperty("season")) {
            for (const [key, value] of Object.entries(props.overrides.season)) {
                updatedSeasonOverrides.push({key, value});
            }
        }

        if (props.overrides.hasOwnProperty("title")) {
            for (const [key, value] of Object.entries(props.overrides.title)) {
                updatedTitleOverrides.push({key, value});
            }
        }

        if (props.overrides.hasOwnProperty("show")) {
            for (const [key, value] of Object.entries(props.overrides.show)) {
                updatedShowOverrides.push({key, value});
            }
        }

        setOriginalFranchiseOverrides(updatedFranchiseOverrides);
        setFranchiseOverrides(updatedFranchiseOverrides);
        setOriginalSeriesOverrides(updatedSeriesOverrides);
        setSeriesOverrides(updatedSeriesOverrides);
        setOriginalSeasonOverrides(updatedSeasonOverrides);
        setSeasonOverrides(updatedSeasonOverrides);
        setOriginalTitleOverrides(updatedTitleOverrides);
        setTitleOverrides(updatedTitleOverrides);
        setOriginalShowOverrides(updatedShowOverrides);
        setShowOverrides(updatedShowOverrides);
    }, [JSON.stringify(props.overrides)]);

    useMemo(function updateAddFranchiseOverrideDisabled() {
        let updatedAddFranchiseOverrideDisabled = false;
        for (const override of franchiseOverrides) {
            updatedAddFranchiseOverrideDisabled ||= override.key === "";
            if (updatedAddFranchiseOverrideDisabled) {
                break;
            }
        }

        setAddFranchiseOverrideDisabled(updatedAddFranchiseOverrideDisabled);
    }, [JSON.stringify(franchiseOverrides)]);

    useMemo(function updateAddSeriesOverrideDisabled() {
        let updatedAddSeriesOverrideDisabled = false;
        for (const override of seriesOverrides) {
            updatedAddSeriesOverrideDisabled ||= override.key === "";
            if (updatedAddSeriesOverrideDisabled) {
                break;
            }
        }

        setAddSeriesOverrideDisabled(updatedAddSeriesOverrideDisabled);
    }, [JSON.stringify(seriesOverrides)]);

    useMemo(function updateAddSeasonOverrideDisabled() {
        let updatedAddSeasonOverrideDisabled = false;
        for (const override of seasonOverrides) {
            updatedAddSeasonOverrideDisabled ||= override.key === "";
            if (updatedAddSeasonOverrideDisabled) {
                break;
            }
        }

        setAddSeasonOverrideDisabled(updatedAddSeasonOverrideDisabled);
    }, []);

    useMemo(function updateAddTitleOverrideDisabled() {
        let updatedAddTitleOverrideDisabled = false;
        for (const override of titleOverrides) {
            updatedAddTitleOverrideDisabled ||= override.key === "";
            if (updatedAddTitleOverrideDisabled) {
                break;
            }
        }

        setAddTitleOverrideDisabled(updatedAddTitleOverrideDisabled);
    }, [JSON.stringify(titleOverrides)]);

    useMemo(function updateAddShowOverrideDisabled() {
        let updatedAddShowOverrideDisabled = false;
        for (const override of showOverrides) {
            updatedAddShowOverrideDisabled ||= override.key === "";
            if (updatedAddShowOverrideDisabled) {
                break;
            }
        }

        setAddShowOverrideDisabled(updatedAddShowOverrideDisabled);
    }, [JSON.stringify(showOverrides)]);

    useMemo(function updateShowWithOverridesApplied() {
        const updatedShow = Object.assign({}, props.selectedShow);
        for (const override of franchiseOverrides) {
            updatedShow[override.key] = override.value;
        }

        for (const override of seriesOverrides) {
            updatedShow[override.key] = override.value;
        }

        for (const override of seasonOverrides) {
            updatedShow[override.key] = override.value;
        }

        for (const override of titleOverrides) {
            updatedShow[override.key] = override.value;
        }

        for (const override of showOverrides) {
            updatedShow[override.key] = override.value;
        }

        setShowWithOverridesApplied(updatedShow);
    }, [JSON.stringify(props.selectedShow), JSON.stringify(franchiseOverrides), JSON.stringify(seriesOverrides), JSON.stringify(titleOverrides), JSON.stringify(showOverrides)]);

    useMemo(function checkUpdates() {
        let updatedUnsavedChanges = false;
         const originalOverridesObject = {
             franchise: {},
             series: {},
             season: {},
             title: {},
             show: {}
         };
         const currentOverridesObject = {
             franchise: {},
             series: {},
             season: {},
             title: {},
             show: {}
         };

         for (const override of originalFranchiseOverrides) {
             originalOverridesObject.franchise[override.key] = override.value;
         }

        for (const override of originalSeriesOverrides) {
            originalOverridesObject.series[override.key] = override.value;
        }

        for (const override of originalSeasonOverrides) {
            originalOverridesObject.season[override.key] = override.value;
        }

        for (const override of originalTitleOverrides) {
            originalOverridesObject.title[override.key] = override.value;
        }

        for (const override of originalShowOverrides) {
            originalOverridesObject.show[override.key] = override.value;
        }

        for (const override of franchiseOverrides) {
            currentOverridesObject.franchise[override.key] = override.value;
        }

        for (const override of seriesOverrides) {
            currentOverridesObject.series[override.key] = override.value;
        }

        for (const override of seasonOverrides) {
            currentOverridesObject.season[override.key] = override.value;
        }

        for (const override of titleOverrides) {
            currentOverridesObject.title[override.key] = override.value;
        }

        for (const override of showOverrides) {
            currentOverridesObject.show[override.key] = override.value;
        }

        for (const [scope, overrides] of Object.entries(originalOverridesObject)) {
            for (const [key, value] of Object.entries(overrides)) {
                updatedUnsavedChanges ||= value !== currentOverridesObject[scope][key];
                if (updatedUnsavedChanges) {
                    setUnsavedChanges(updatedUnsavedChanges);
                    return;
                }
            }
        }

        for (const [scope, overrides] of Object.entries(currentOverridesObject)) {
            for (const [key, value] of Object.entries(overrides)) {
                updatedUnsavedChanges ||= value !== originalOverridesObject[scope][key];
                if (updatedUnsavedChanges) {
                    setUnsavedChanges(updatedUnsavedChanges);
                    return;
                }
            }
        }
        setUnsavedChanges(updatedUnsavedChanges);
    }, [JSON.stringify(originalFranchiseOverrides), JSON.stringify(originalSeriesOverrides), JSON.stringify(originalSeasonOverrides), JSON.stringify(originalTitleOverrides), JSON.stringify(originalShowOverrides), JSON.stringify(franchiseOverrides), JSON.stringify(seriesOverrides), JSON.stringify(seasonOverrides), JSON.stringify(titleOverrides), JSON.stringify(showOverrides)]);

    const saveOverrides = () => {
        const franchiseOverridesToSave = {};
        for (const override of franchiseOverrides) {
            franchiseOverridesToSave[override.key] = override.value;
        }

        const seriesOverridesToSave = {};

        for (const override of seriesOverrides) {
            seriesOverridesToSave[override.key] = override.value;
        }

        const seasonOverridesToSave = {};
        for (const override of seasonOverrides) {
            seasonOverridesToSave[override.key] = override.value;
        }

        const titleOverridesToSave = {};
        for (const override of titleOverrides) {
            titleOverridesToSave[override.key] = override.value;
        }

        const showOverridesToSave = {};
        for (const override of showOverrides) {
            showOverridesToSave[override.key] = override.value;
        }

        const overridesToSave = [
            {
                franchise_id: franchiseId,
                overrides: franchiseOverridesToSave
            }, {
                series_id: seriesId,
                overrides: seriesOverridesToSave
            }, {
                season_id: seasonId,
                overrides: seasonOverridesToSave
            }, {
                title_id: titleId,
                overrides: titleOverridesToSave
            }, {
                show_id: showId,
                overrides: showOverridesToSave
            }
        ]

        props.saveOverrides(overridesToSave);
    };

    return (
        <Grid className="masterContainer" divided>
            {
                props.successMessage ?
                    <Message icon success>
                        <Icon name="check" />
                        <Message.Content>{props.successMessage}</Message.Content>
                    </Message> :
                props.errorMessage ?
                    <Message icon error>
                        <Icon name="warning" />
                        <Message.Content>{props.errorMessage}</Message.Content>
                    </Message> : ""
            }
            <Grid.Row>
                <Container fluid>
                    <Button floated="left" onClick={() => props.setEditingOverrides(false)}><Icon name="arrow left"/>Go Back</Button>
                    <Popup
                        open={unsavedChanges}
                        content="You have made unsaved changes."
                        position="left center"
                        trigger={
                            <ContingentButton
                                floated="right"
                                primary
                                onClick={saveOverrides}
                                disabled={saveDisabled || !props.userCanEdit}
                                allPermissions={props.allPermissions}
                                module={props.module}
                                scope={instance}
                                service={props.service}
                                user={props.user}
                                hideIfNotPermitted={true}
                            >
                                <Icon name={props.savingOverrides ? "spinner" : "save"} loading={props.savingOverrides} />Save&nbsp;Overrides
                            </ContingentButton>
                        }
                    />
                </Container>
            </Grid.Row>
            <Grid.Row>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Show ID</Table.HeaderCell>
                            <Table.HeaderCell>Title ID</Table.HeaderCell>
                            <Table.HeaderCell>Season ID</Table.HeaderCell>
                            <Table.HeaderCell>Series ID</Table.HeaderCell>
                            <Table.HeaderCell>Franchise ID</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>{showId}</Table.Cell>
                            <Table.Cell>{titleId}</Table.Cell>
                            <Table.Cell>{seasonId}</Table.Cell>
                            <Table.Cell>{seriesId}</Table.Cell>
                            <Table.Cell>{franchiseId}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={4}>
                    <Segment basic>
                        <Header dividing textAlign="center">Original Payload</Header>
                        <pre>{JSON.stringify(props.selectedShow, null, 4)}</pre>
                    </Segment>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Tab
                        panes={[
                            franchiseId.length > 0 && {
                                menuItem: "Franchise",
                                render: () =>
                                    <TimeMachineRundownCMSOverrideEditorTable
                                        overrides={franchiseOverrides}
                                        setOverrides={setFranchiseOverrides}
                                        addOverrideDisabled={addFranchiseOverrideDisabled}
                                        cmsAttrsAllowed={props.cmsAttrsAllowed}
                                        scope="Franchise"
                                        userCanEdit={props.userCanEdit}
                                        allPermissions={props.allPermissions}
                                        module={props.module}
                                        instance={instance}
                                        service={props.service}
                                        user={props.user}
                                    />
                            },
                            seriesId.length > 0 && {
                                menuItem: "Series",
                                render: () =>
                                    <TimeMachineRundownCMSOverrideEditorTable
                                        overrides={seriesOverrides}
                                        setOverrides={setSeriesOverrides}
                                        addOverrideDisabled={addSeriesOverrideDisabled}
                                        cmsAttrsAllowed={props.cmsAttrsAllowed}
                                        scope="Series"
                                        userCanEdit={props.userCanEdit}
                                        allPermissions={props.allPermissions}
                                        module={props.module}
                                        instance={instance}
                                        service={props.service}
                                        user={props.user}
                                    />
                            },
                            seasonId.length > 0 && {
                                menuItem: "Season",
                                render: () =>
                                    <TimeMachineRundownCMSOverrideEditorTable
                                        overrides={seasonOverrides}
                                        setOverrides={setSeasonOverrides}
                                        addOverrideDisabled={addSeasonOverrideDisabled}
                                        cmsAttrsAllowed={props.cmsAttrsAllowed}
                                        scope="Season"
                                        userCanEdit={props.userCanEdit}
                                        allPermissions={props.allPermissions}
                                        module={props.module}
                                        instance={instance}
                                        service={props.service}
                                        user={props.user}
                                    />
                            },
                            titleId.length > 0 && {
                                menuItem: "Title",
                                render: () =>
                                    <TimeMachineRundownCMSOverrideEditorTable
                                        overrides={titleOverrides}
                                        setOverrides={setTitleOverrides}
                                        addOverrideDisabled={addTitleOverrideDisabled}
                                        cmsAttrsAllowed={props.cmsAttrsAllowed}
                                        scope="Title"
                                        userCanEdit={props.userCanEdit}
                                        allPermissions={props.allPermissions}
                                        module={props.module}
                                        instance={instance}
                                        service={props.service}
                                        user={props.user}
                                    />
                            },
                            showId.length > 0 && {
                                menuItem: "Show",
                                render: () =>
                                    <TimeMachineRundownCMSOverrideEditorTable
                                        overrides={showOverrides}
                                        setOverrides={setShowOverrides}
                                        addOverrideDisabled={addShowOverrideDisabled}
                                        cmsAttrsAllowed={props.cmsAttrsAllowed}
                                        scope="Show"
                                        userCanEdit={props.userCanEdit}
                                        allPermissions={props.allPermissions}
                                        module={props.module}
                                        instance={instance}
                                        service={props.service}
                                        user={props.user}
                                    />
                            }
                        ]}
                    />
                </Grid.Column>
                <Grid.Column width={4}>
                    <Segment basic>
                        <Header dividing textAlign="center">Payload with Overrides Applied</Header>
                        <pre>{JSON.stringify(showWithOverridesApplied, null, 4)}</pre>
                    </Segment>
                </Grid.Column>
            </Grid.Row>

        </Grid>
    );
};
