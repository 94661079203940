import React, {useMemo, useState} from "react";
import {Table, Form, Input, Select} from "semantic-ui-react";
import ContingentButton from "../../ContingentButton";

export default function TimeMachineRundownCMSOverrideEditorTableRow(props) {
    const [cmsAttrsAllowedOptions, setCmsAttrsAllowedOptions] = useState([]);

    useMemo(() => {
        console.log("overrides: ", props.overrides);
        const updatedCmsAttrsAllowedOptions = [];
        if (props.overrideKey) {
            updatedCmsAttrsAllowedOptions.push({key: props.overrideKey, text: props.overrideKey, value: props.overrideKey});
        }

        for (const attr of props.cmsAttrsAllowed) {
            if (!props.overrideKeys.includes(attr)) {
                updatedCmsAttrsAllowedOptions.push({key: attr, text: attr, value: attr});
            }
        }

        setCmsAttrsAllowedOptions(updatedCmsAttrsAllowedOptions);
    }, [JSON.stringify(props.cmsAttrsAllowed), JSON.stringify(props.overrideKeys)]);

    return (
        <Table.Row>
            <Table.Cell>
                <Form.Field
                    control={Select}
                    options={cmsAttrsAllowedOptions}
                    fluid
                    search
                    value={props.overrideKey}
                    onChange={(event, {value}) => props.setOverrideKey(value)}
                    readOnly={!props.userCanEdit}
                />
            </Table.Cell>
            <Table.Cell>
                <Form.Field
                    control={Input}
                    fluid
                    value={props.overrideValue}
                    onChange={(event, {value}) => props.setOverrideValue(value)}
                    readOnly={!props.userCanEdit}
                />
            </Table.Cell>
            <Table.Cell>
                <Form.Field
                    control={ContingentButton}
                    icon="minus"
                    onClick={() => props.removeOverride(props.index)}
                    readOnly={!props.userCanEdit}
                    allPermissions={props.allPermissions}
                    module={props.module}
                    scope={props.instance}
                    service={props.service}
                    user={props.user}
                    hideIfNotPermitted={true}
                />
            </Table.Cell>
        </Table.Row>
    );
};
