import React, {useEffect, useMemo, useState} from "react";
import {Segment, Button, Table, Container, Icon, Image, Message, Header, Divider, Tab, Menu} from "semantic-ui-react";


export default function TimeMachineRundownCMSSubComponent(props) {
    const [showWithOverridesApplied, setShowWithOverridesApplied] = useState({});

    const supportedImages = useMemo(() => {
        const instance = props.show?.feed_id ?? "";
        const supportedImages = props.metadata?.[instance]?.["images_supported"] ?? [];
        console.log(`TimeMachineRundownCMSSubComponent.supportedImages: for instance ${instance}, found supported images ${supportedImages}, with images `, props.images);
        return supportedImages;
    }, [JSON.stringify(props.show), JSON.stringify(props.metadata)]);


    useEffect(function updateShowWithOverridesApplied() {
        const updatedShow = Object.assign({}, props.show);

        console.log("TimeMachineRundownCMSSubComponent.updateShowWithOverridesApplied: overrides: ", props.overrides, "show: ", props.show, "images: ", props.images);
        if (props.overrides.hasOwnProperty("franchise")) {
            for (const [key, value] of Object.entries(props.overrides.franchise)) {
                updatedShow[key] = value;
            }
        }

        if (props.overrides.hasOwnProperty("series")) {
            for (const [key, value] of Object.entries(props.overrides.series)) {
                updatedShow[key] = value;
            }
        }

        if (props.overrides.hasOwnProperty("season")) {
            for (const [key, value] of Object.entries(props.overrides.season)) {
                updatedShow[key] = value;
            }
        }

        if (props.overrides.hasOwnProperty("title")) {
            for (const [key, value] of Object.entries(props.overrides.title)) {
                updatedShow[key] = value;
            }
        }

        if (props.overrides.hasOwnProperty("show")) {
            for (const [key, value] of Object.entries(props.overrides.show)) {
                updatedShow[key] = value;
            }
        }

        // TODO: Apply image overrides

        setShowWithOverridesApplied(updatedShow);
    }, [JSON.stringify(props.overrides), JSON.stringify(props.images), JSON.stringify(props.show)]);

    return (
        <Segment fluid>
            {
                props.loadingOverrides ?
                    <Message icon color="yellow">
                        <Icon name="spinner" loading />
                        <Message.Content>Loading overrides...</Message.Content>
                    </Message> :
                props.loadingImages ?
                    <Message icon color="yellow">
                        <Icon color="yellow" name="spinner" loading />
                        <Message.Content>Loading images...</Message.Content>
                    </Message> :
                    <Container fluid>
                        <Button disabled color="blue" onClick={() => props.setEditOverridesClicked(true)} floated="right"><Icon name="edit outline"/>Edit Metadata</Button>
                        <Button color="blue" onClick={() => props.setEditImagesClicked(true)} floated="right" disabled={Object.keys(props.imageSpecs).length < 1}><Icon name="images outline"/>Edit Images</Button>
                        <Table basic fixed celled verticalAlign="top" fluid>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Time Machine Payload</Table.HeaderCell>
                                    <Table.HeaderCell>Images</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell verticalAlign="top"><pre>{JSON.stringify(showWithOverridesApplied, null, 4)}</pre></Table.Cell>
                                    <Table.Cell verticalAlign="top">
                                        <Table basic celled fixed fluid>
                                            <Table.Body>
                                                {
                                                    supportedImages.length < 1 ?
                                                        <Message icon color="yellow">
                                                            <Icon name="info" />
                                                            <Message.Content>
                                                                <p>
                                                                    The instance for this show has no configured image types to view or modify.
                                                                </p>
                                                                <p>
                                                                    Please contact an administrator if you wish to configure image support for this instance.
                                                                </p>
                                                            </Message.Content>
                                                        </Message> :
                                                    <Tab
                                                        defaultActiveIndex={1}
                                                        panes={
                                                            [
                                                                {
                                                                    menuItem: <Menu.Item disabled><Icon name="language" size="big" /></Menu.Item>
                                                                },
                                                                ...Object.keys(props.images.i18n).map((language, index) => {
                                                                    console.log(`images for language ${language}: `, props.images.i18n[language]);
                                                                    return {
                                                                        menuItem: language,
                                                                        render: () =>
                                                                            <Container textAlign="center">
                                                                                {
                                                                                    Object.values(props.images.i18n?.[language] ?? {}).filter(image => supportedImages.indexOf(image.imageId) >= 0).map(image => {
                                                                                        console.log("TimeMachineRundownCMSSubComponent.render: rendering image: ", image)
                                                                                        return (
                                                                                            <Container key={`${language}-${image.imageId}`} textAlign="center" fluid>
                                                                                                <Table>
                                                                                                    <Table.Body>
                                                                                                        <Table.Row>
                                                                                                            <Table.Cell>
                                                                                                                <Header textAlign="center">{image.name}</Header>
                                                                                                            </Table.Cell>
                                                                                                        </Table.Row>
                                                                                                        <Table.Row>
                                                                                                            <Table.Cell>
                                                                                                                <Image size="medium" label={image.url ? {as: "a", color: "blue", content: image.scope, ribbon: true} : false} fluid src={image.url || props.imageNotFoundURL || "https://akamai.warnermediacdn.com/inflow/lower/18c8d64c-9081-4a3d-b15a-a2cdcd6ef19e.jpeg"} centered />
                                                                                                            </Table.Cell>
                                                                                                        </Table.Row>
                                                                                                        <Table.Row>
                                                                                                            <Table.Cell>
                                                                                                                <Divider />
                                                                                                            </Table.Cell>
                                                                                                        </Table.Row>
                                                                                                    </Table.Body>
                                                                                                </Table>

                                                                                            </Container>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </Container>
                                                                    };
                                                                })
                                                            ]

                                                        }
                                                    />
                                                }
                                            </Table.Body>
                                        </Table>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Container>
            }
        </Segment>
    );
};
