import React, {useEffect, useState} from "react";
import TimeMachineRundownCMSOverrideEditorView from "./TimeMachineRundownCMSOverrideEditorView";
import TimeMachineRundownCMSSearchView from "./TimeMachineRundownCMSSearchView";
import TimeMachineRundownCMSImageManagerView from "./TimeMachineRundownCMSImageManagerView";
import TimeMachineDataProvider from "../../../Services/TimeMachineDataProvider";
import {useHistory, useLocation} from "react-router";

const ONE_DAY_MS = 24 * 60 * 60 * 1000;
const FOUR_HOURS_MS = 1000 * 60 * 60 * 4;
const NOW_MS = new Date().getTime();
const INITIAL_END_MS = NOW_MS + parseInt((1.5 * ONE_DAY_MS).toString());
const INITIAL_START_MS = NOW_MS - FOUR_HOURS_MS;

export default function TimeMachineRundownCMSMasterDetailView(props) {
    const [metadata, setMetadata] = useState({});
    const [defaultMetadata, setDefaultMetadata] = useState({});
    const [instanceMetadata, setInstanceMetadata] = useState({});
    const [imageSpecs, setImageSpecs] = useState({});
    const [imageNotFoundURL, setImageNotFoundURL] = useState("");
    const [cmsAttrsAllowed, setCmsAttrsAllowed] = useState([]);
    const [languagesSupported, setLanguagesSupported] = useState([]);
    const [defaultImages, setDefaultImages] = useState([]);
    const [instances, setInstances] = useState([]);
    const [instanceLimits, setInstanceLimits] = useState([]);
    const [editableInstances, setEditableInstances] = useState([]);
    const [userCanEdit, setUserCanEdit] = useState(false);
    const [instanceOptions, setInstanceOptions] = useState([]);
    const [instanceImageSpecs, setInstanceImageSpecs] = useState({});
    const [selectedInstance, setSelectedInstance] = useState("");
    const [selectedFranchiseId, setSelectedFranchiseId] = useState("");
    const [selectedSeriesId, setSelectedSeriesId] = useState("");
    const [selectedSeasonId, setSelectedSeasonId] = useState("");
    const [selectedPropEp, setSelectedPropEp] = useState("");
    const [selectedTitleId, setSelectedTitleId] = useState("");
    const [selectedExternalId, setSelectedExternalId] = useState("");
    const [selectedShowId, setSelectedShowId] = useState("");
    const [selectedStartTime, setSelectedStartTime] = useState(INITIAL_START_MS);
    const [selectedEndTime, setSelectedEndTime] = useState(INITIAL_END_MS);
    const [editingOverrides, setEditingOverrides] = useState(false);
    const [shows, setShows] = useState([]);
    const [overrides, setOverrides] = useState({});
    const [imageOverrides, setImageOverrides] = useState({});
    const [images, setImages] = useState({});
    const [selectedShow, setSelectedShow] = useState({});
    const [searching, setSearching] = useState(false);
    const [editingImages, setEditingImages] = useState(false);
    const [savingOverrides, setSavingOverrides] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [savingImages, setSavingImages] = useState(false);
    const [searchClicked, setSearchClicked] = useState(false);
    const [loadingInstances, setLoadingInstances] = useState(false);
    const [loadingOverrides, setLoadingOverrides] = useState(false);
    const [loadingImages, setLoadingImages] = useState(false);
    const [doSearch, setDoSearch] = useState(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState("");

    const history = useHistory();
    const {search} = useLocation();

    TimeMachineDataProvider.init(process.env.TIME_MACHINE_ADMIN_URL);

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        let doSearch = false;
        if (searchParams.has("instance")) {
            setSelectedInstance(searchParams.get("instance"));
            doSearch = true;
        }

        if (searchParams.has("showId")) {
            setSelectedShowId(searchParams.get("showId"));
            doSearch = true;
        }

        if (searchParams.has("titleId")) {
            setSelectedTitleId(searchParams.get("titleId"));
            doSearch = true;
        }

        if (searchParams.has("seasonId")) {
            setSelectedSeasonId(searchParams.get("seasonId"));
            doSearch = true;
        }

        if (searchParams.has("seriesId")) {
            setSelectedSeriesId(searchParams.get("seriesId"));
            doSearch = true;
        }

        if (searchParams.has("franchiseId")) {
            setSelectedFranchiseId(searchParams.get("franchiseId"));
            doSearch = true;
        }

        if (searchParams.has("externalId")) {
            setSelectedExternalId(searchParams.get("externalId"));
            doSearch = true;
        }

        if (searchParams.has("startTime")) {
            setSelectedStartTime(parseInt(searchParams.get("startTime")));
            doSearch = true;
        }

        if (searchParams.has("endTime")) {
            setSelectedEndTime(parseInt(searchParams.get("endTime")));
            doSearch = true;
        }
        setDoSearch(doSearch);
    }, [JSON.stringify(instances), search]);

    useEffect(function updateInstanceLimits() {
        const updatedInstanceLimits = [];
        const updatedEditableInstances = [];
        for (const permission of props.userPermissions) {
            if (permission.urn === "urn:all:aspen-power") {
                setInstanceLimits(false);
                setEditableInstances(false);
                return;
            } else if (permission.service === props.service && permission.module === props.module) {
                if (permission.scope === "all") {
                    setInstanceLimits(false);
                    if (permission.role === "editor") {
                        setEditableInstances(false);
                    }
                    return;
                }
                updatedInstanceLimits.push(permission.scope);
                if (permission.role === "editor") {
                    updatedEditableInstances.push(permission.scope);
                }
            }
        }

        setInstanceLimits(updatedInstanceLimits);
        setEditableInstances(updatedEditableInstances);
    }, [JSON.stringify(props.userPermissions)]);

    useEffect(function updateUserCanEdit() {
        const selectedShowExists = selectedShow !== null && Object.keys(selectedShow).length > 0;
        const userCanEditAll = editableInstances === false;
        const isFeedIdInEditableInstances = Array.isArray(editableInstances) && selectedShowExists && editableInstances.includes(selectedShow.feed_id);
        const updatedUserCanEdit = selectedShowExists && (userCanEditAll || isFeedIdInEditableInstances);
        console.log(`TimeMachineRundownCMSMasterDetailView.updateUserCanEdit: selected show exists? ${selectedShowExists}; user can edit all? ${userCanEditAll}; is feed ID in editable instances? ${isFeedIdInEditableInstances}; user can edit? ${updatedUserCanEdit}`);
        setUserCanEdit(updatedUserCanEdit);
    }, [JSON.stringify(selectedShow), JSON.stringify(editableInstances)]);

    useEffect(function updateMetadata() {
        TimeMachineDataProvider.getWhitelistedMetadata().then(response => {
            setMetadata(response);
        });
    }, []);

    useEffect(function updateDefaultMetadata() {
        setDefaultMetadata(metadata?.default ?? {});
    }, [JSON.stringify(metadata)]);

    useEffect(function initialize() {
        setLoadingInstances(true);
        TimeMachineDataProvider.getInstances().then(response => {
            if (Array.isArray(response)) {
                response.sort();
                setInstances(response);
            }
        }).catch(error => {
            setErrorMessage("There was an error getting instances.");
            console.error(error);
        }).finally(() => {
            setLoadingInstances(false);
        });
    }, []);

    useEffect(function initInstanceOptions() {
        const updatedInstanceOptions = [];
        for (const instance of instances) {
            if (instanceLimits === false || instanceLimits.includes(instance)) {
                updatedInstanceOptions.push({key: instance, text: instance, value: instance});
            }
        }

        setInstanceOptions(updatedInstanceOptions);
    }, [JSON.stringify(instances), JSON.stringify(instanceLimits), JSON.stringify(editableInstances)]);

    useEffect(function updateInstanceImageSpecs() {
        if (selectedShow.feed_id) {
            const instanceSupportedImages = metadata?.[selectedShow.feed_id]?.["images_supported"] ?? [];
            const updatedInstanceImageSpecs = {};
            for (const imageId of instanceSupportedImages) {
                if (imageSpecs.hasOwnProperty(imageId)) {
                    updatedInstanceImageSpecs[imageId] = Object.assign({}, imageSpecs[imageId]);
                }
            }

            console.log(`TimeMachineCMSMasterDetailView.updateInstanceImageSpecs: instance ${selectedShow.feed_id}, image specs: `, updatedInstanceImageSpecs);

            setInstanceImageSpecs(updatedInstanceImageSpecs);
        }

    }, [JSON.stringify(selectedShow), JSON.stringify(imageSpecs)]);

    useEffect(function onUpdateSelectedInstance() {
        let updatedInstanceMetadata = Object.assign({}, metadata?.[selectedInstance] ?? {});

        console.log("TimeMachineRundownCMSMasterDetailView.onUpdateSelectedInstance: instance metadata: ", updatedInstanceMetadata, "metadata: ", metadata);
        setInstanceMetadata(updatedInstanceMetadata);
    }, [selectedInstance, JSON.stringify(metadata)]);

    useEffect(function updateSupportedLanguages() {
        if (doSearch || languagesSupported.length < 1) {
            let updatedLanguagesSupported = ["en-US"];
            if (defaultMetadata.hasOwnProperty("languages_supported")) {
                updatedLanguagesSupported = defaultMetadata.languages_supported.slice();
            }
            if (instanceMetadata.hasOwnProperty("languages_supported")) {
                updatedLanguagesSupported = instanceMetadata.languages_supported.slice();
            }
            setLanguagesSupported(updatedLanguagesSupported);
        }
    }, [JSON.stringify(instanceMetadata), JSON.stringify(defaultMetadata), doSearch]);

    useEffect(() => {
        let updatedImageSpecs = Object.assign({}, defaultMetadata?.["image_specs"] ?? {});
        let updatedDefaultImages = defaultMetadata?.["default_images"]?.slice() ?? [];
        let updatedCmsAttrsAllowed = defaultMetadata?.["cms_attrs_allowed"]?.slice() ?? [];
        let updatedImageNotFoundURL = defaultMetadata?.["image_not_found_url"] ?? "";

        setImageSpecs(updatedImageSpecs);
        setDefaultImages(updatedDefaultImages);
        setCmsAttrsAllowed(updatedCmsAttrsAllowed);
        setImageNotFoundURL(updatedImageNotFoundURL);
    }, [JSON.stringify(defaultMetadata)]);

    useEffect(() => {
        if (editingOverrides) {
            setSuccessMessage("");
            setErrorMessage("");
            setEditingImages(false);
        }
    }, [editingOverrides]);

    useEffect(() => {
        if (editingImages) {
            setSuccessMessage("");
            setErrorMessage("");
            setEditingOverrides(false);
        }
    }, [editingImages]);

    useEffect(() => {
        if (Object.keys(selectedShow).length > 0) {
            loadOverrides();
        }
    }, [JSON.stringify(selectedShow)]);

    useEffect(function onUpdateDoSearch() {
        if (doSearch) {
            setDoSearch(false);
            performSearch();
        }
    }, [doSearch]);

    useEffect(function onUpdateImages() {
        const updatedImages = {i18n: {}};
        const feedId = selectedShow.feed_id ?? "";
        const imagesSupported = metadata?.[feedId]?.["images_supported"] ?? [];
        for (const language of languagesSupported) {
            if (!updatedImages.i18n.hasOwnProperty(language)) {
                updatedImages.i18n[language] = {};
            }

            for (const imageId of imagesSupported) {
                if (imageSpecs.hasOwnProperty(imageId)) {
                    updatedImages.i18n[language][imageId] = Object.assign({imageId, scope: "None"}, imageSpecs[imageId]);
                }
            }
        }

        console.log("TimeMachineRundownCMSMasterDetailView.onUpdateImages: image specs: ", imageSpecs, "updated images: ", updatedImages);

        if (Object.keys(selectedShow).length > 0) {
            let baselineDefaultImages = {};
            let matchingDefaultImages = null;
            for (const defaultImageMetadata of defaultImages) {
                if (defaultImageMetadata.hasOwnProperty("show_attrs")) {
                    const show_attrs = defaultImageMetadata.show_attrs;
                    if (Object.keys(show_attrs).length < 1) {
                        baselineDefaultImages = Object.assign({}, defaultImageMetadata);
                    }
                    let showAttrsMatch = true;
                    for (const key of Object.keys(show_attrs)) {
                        showAttrsMatch &&= selectedShow.hasOwnProperty(key) && show_attrs[key].includes(selectedShow[key]);
                        if (!showAttrsMatch) {
                            console.log(`TimeMachineRundownCMSMasterDetailView.onUpdateImages: default image metadata skipped because show doesn't have key ${key} or because ${selectedShow.hasOwnProperty(key) ? selectedShow[key] : null} isn't in ${show_attrs[key]}`, selectedShow, show_attrs)
                        }
                    }

                    if (showAttrsMatch) {
                        matchingDefaultImages = Object.assign({}, defaultImageMetadata);
                        console.log("TimeMachineRundownCMSMasterDetailView.onUpdateImages: found default images that match: ", defaultImageMetadata);
                    }
                }

                if (matchingDefaultImages != null) {
                    for (const language of languagesSupported) {
                        for (const [imageId, value] of Object.entries(updatedImages.i18n[language])) {
                            if (matchingDefaultImages.i18n.hasOwnProperty(language) && matchingDefaultImages.i18n[language].hasOwnProperty(imageId) && !value.url) {
                                value.url = matchingDefaultImages.i18n[language][imageId];
                                value.scope = "Default";
                            }
                        }
                    }
                } else {
                    for (const language of languagesSupported) {
                        for (const [imageId, value] of Object.entries(updatedImages.i18n[language])) {
                            if (baselineDefaultImages.i18n?.[language].hasOwnProperty(imageId) && !value.url) {
                                value.url = baselineDefaultImages.i18n[language][imageId];
                                value.scope = "Default";
                            }
                        }
                    }
                }
            }
            if (selectedShow.hasOwnProperty("images")) {
                console.log("images from source: ", selectedShow.images);
                for (const language of languagesSupported) {
                    if (selectedShow.images && selectedShow.images.hasOwnProperty("i18n") && selectedShow.images.i18n.hasOwnProperty(language)) {
                        for (const image of selectedShow.images.i18n[language]) {
                            for (const imageId of Object.keys(instanceImageSpecs)) {
                                if (image.id === imageId) {
                                    updatedImages.i18n[language][imageId] = Object.assign(updatedImages.i18n[language][imageId], image, {name: instanceImageSpecs[imageId].name, scope: "Source"});
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }

        console.log("TimeMachineRundownCMSMasterDetailView.onUpdateImages: updating images from time machine show: ", updatedImages, "image overrides: ", imageOverrides);

        if (imageOverrides.hasOwnProperty("franchise")) {
            for (const language of languagesSupported) {
                if (!imageOverrides.franchise.hasOwnProperty("i18n")) {
                    const imagesForLanguage = {};
                    for (const imageId of imagesSupported) {
                        imagesForLanguage[imageId] = Object.assign({}, imageOverrides.franchise?.[imageId] ?? {});
                        delete imageOverrides.franchise[imageId];
                    }
                    imageOverrides.franchise.i18n[language] = Object.assign({}, {[language]: imagesForLanguage});
                }
                if (!imageOverrides.franchise.i18n.hasOwnProperty(language)) {
                    imageOverrides.franchise.i18n[language] = {};
                }
                for (const imageId of Object.keys(imageOverrides?.franchise?.i18n?.[language]) ?? []) {
                    if (imageId === "override_source") {
                        continue;
                    }

                    console.log(`TimeMachineRundownCMSMasterDetailView.onUpdateImages: imageId: ${imageId}`)

                    const override = imageOverrides.franchise.i18n[language][imageId];
                    const isOverrideSource = imageOverrides.franchise.override_source === true;
                    const isCurrentDefault = ["None", "Default"].includes(updatedImages.i18n[language]?.[imageId]?.scope);
                    const isCurrentExisting = updatedImages.i18n[language]?.[imageId]?.url;
                    let should_override = override.url && (isOverrideSource || isCurrentDefault || !isCurrentExisting);

                    console.log(`TimeMachineRundownCMSMasterDetailView.onUpdateImages: imageId: ${imageId}; url: ${override.url}; override source? ${isOverrideSource}; is current default? ${isCurrentDefault}`);
                    if (should_override) {
                        updatedImages.i18n[language][imageId] = Object.assign({}, updatedImages.i18n[language][imageId], override, {override_source: imageOverrides.franchise.override_source, scope: "Franchise"});
                    }
                }
            }

        }
        if (imageOverrides.hasOwnProperty("series")) {
            for (const language of languagesSupported) {
                if (!imageOverrides.series.hasOwnProperty("i18n")) {
                    const imagesForLanguage = {};
                    for (const imageId of imagesSupported) {
                        imagesForLanguage[imageId] = Object.assign({}, imageOverrides.series?.[imageId] ?? {});
                        delete imageOverrides.series[imageId];
                    }
                    imageOverrides.series.i18n[language] = Object.assign({}, {[language]: imagesForLanguage});
                }

                for (const imageId of Object.keys(imageOverrides?.series?.i18n?.[language] ?? {})) {
                    if (imageId === "override_source") {
                        continue;
                    }

                    const override = imageOverrides.series.i18n[language][imageId];
                    const isOverrideSource = imageOverrides.series.override_source === true;
                    const isCurrentDefault = ["None", "Default", "Franchise"].includes(updatedImages.i18n[language]?.[imageId]?.scope);
                    const isCurrentExisting = updatedImages.i18n[language]?.[imageId]?.url;
                    let should_override = override.url && (isOverrideSource || isCurrentDefault || !isCurrentExisting);
                    console.log(`TimeMachineRundownCMSMasterDetailView.onUpdateImages: imageId: ${imageId}; url: ${override.url}; override source? ${isOverrideSource}; is current default? ${isCurrentDefault}`);

                    if (should_override) {
                        updatedImages.i18n[language][imageId] = Object.assign({}, updatedImages.i18n[language][imageId], override, {override_source: imageOverrides.series.override_source, scope: "Series"});
                    }
                }
            }
        }

        if (imageOverrides.hasOwnProperty("season")) {
            for (const language of languagesSupported) {
                if (!imageOverrides.season.hasOwnProperty("i18n")) {
                    const imagesForLanguage = {};
                    for (const imageId of imagesSupported) {
                        imagesForLanguage[imageId] = Object.assign({}, imageOverrides.season?.[imageId] ?? {});
                        delete imageOverrides.season[imageId];
                    }
                    imageOverrides.season.i18n[language] = Object.assign({}, {[language]: imagesForLanguage});
                }

                for (const imageId of Object.keys(imageOverrides?.season?.i18n?.[language])) {
                    if (imageId === "override_source") {
                        continue;
                    }

                    const override = imageOverrides.season.i18n[language][imageId];
                    const isOverrideSource = imageOverrides.season.override_source === true;
                    const isCurrentDefault = ["None", "Default", "Franchise", "Series"].includes(updatedImages.i18n[language]?.[imageId]?.scope);
                    const isCurrentExisting = updatedImages.i18n[language]?.[imageId]?.url;
                    let should_override = override.url && (isOverrideSource || isCurrentDefault || !isCurrentExisting);
                    console.log(`TimeMachineRundownCMSMasterDetailView.onUpdateImages: imageId: ${imageId}; url: ${override.url}; override source? ${isOverrideSource}; is current default? ${isCurrentDefault}`);

                    if (should_override) {
                        updatedImages.i18n[language][imageId] = Object.assign({}, updatedImages.i18n[language][imageId], override, {override_source: imageOverrides.season.override_source, scope: "Season"});
                    }
                }
            }
        }

        if (imageOverrides.hasOwnProperty("prop_ep")) {
            for (const language of languagesSupported) {
                if (!imageOverrides.prop_ep.hasOwnProperty("i18n")) {
                    const imagesForLanguage = {};
                    for (const imageId of imagesSupported) {
                        imagesForLanguage[imageId] = Object.assign({}, imageOverrides.prop_ep?.[imageId] ?? {});
                        delete imageOverrides.prop_ep[imageId];
                    }
                    imageOverrides.prop_ep.i18n[language] = Object.assign({}, {[language]: imagesForLanguage});
                }

                for (const imageId of Object.keys(imageOverrides.prop_ep.i18n?.[language]) ?? []) {
                    if (imageId === "override_source") {
                        continue;
                    }

                    const override = imageOverrides.prop_ep.i18n[language][imageId];
                    const isOverrideSource = imageOverrides.prop_ep.override_source === true;
                    const isCurrentDefault = ["None", "Default", "Franchise", "Series", "Season"].includes(updatedImages.i18n[language]?.[imageId]?.scope);
                    const isCurrentExisting = updatedImages.i18n[language]?.[imageId]?.url;
                    console.log(`TimeMachineRundownCMSMasterDetailView.onUpdateImages: imageId: ${imageId}; url: ${override.url}; override source? ${isOverrideSource}; is current default? ${isCurrentDefault}, override: `, override);

                    let should_override = override && override.url && (isOverrideSource || isCurrentDefault || !isCurrentExisting);

                    if (should_override) {
                        updatedImages.i18n[language][imageId] = Object.assign({}, updatedImages.i18n[language][imageId], override, {override_source: imageOverrides.prop_ep.override_source, scope: "Title by Prop Ep"});
                    }
                }
            }
            console.log("TimeMachineRundownCMSMasterDetailView.onUpdateImages: show image overrides: ", imageOverrides.prop_ep);
        }

        if (imageOverrides.hasOwnProperty("title")) {
            for (const language of languagesSupported) {
                if (!imageOverrides.title.hasOwnProperty("i18n")) {
                    const imagesForLanguage = {};
                    for (const imageId of imagesSupported) {
                        imagesForLanguage[imageId] = Object.assign({}, imageOverrides.title?.[imageId] ?? {});
                        delete imageOverrides.title[imageId];
                    }
                    imageOverrides.title.i18n[language] = Object.assign({}, {[language]: imagesForLanguage});
                }

                for (const imageId of Object.keys(imageOverrides?.title?.i18n?.[language] ?? {})) {
                    if (imageId === "override_source") {
                        continue;
                    }

                    const override = imageOverrides.title.i18n[language][imageId];
                    const isOverrideSource = imageOverrides.title.override_source === true;
                    const isCurrentDefault = ["None", "Default", "Franchise", "Series", "Season", "Title by Prop Ep"].includes(updatedImages.i18n[language]?.[imageId]?.scope);
                    const isCurrentExisting = updatedImages.i18n[language]?.[imageId]?.url;
                    let should_override = override.url && (isOverrideSource || isCurrentDefault || !isCurrentExisting);
                    console.log(`TimeMachineRundownCMSMasterDetailView.onUpdateImages: imageId: ${imageId}; url: ${override.url}; override source? ${isOverrideSource}; is current default? ${isCurrentDefault}`);

                    if (should_override) {
                        updatedImages.i18n[language][imageId] = Object.assign({}, updatedImages.i18n[language][imageId], override,{override_source: imageOverrides.title.override_source, scope: "Title by Title Id"});
                    }
                }
            }
            console.log("TimeMachineRundownCMSMasterDetailView.onUpdateImages: title image overrides: ", imageOverrides.title);
        }

        if (imageOverrides.hasOwnProperty("external_id")) {
            for (const language of languagesSupported) {
                if (!imageOverrides.external_id.hasOwnProperty("i18n")) {
                    const imagesForLanguage = {};
                    for (const imageId of imagesSupported) {
                        imagesForLanguage[imageId] = Object.assign({}, imageOverrides.external_id?.[imageId] ?? {});
                        delete imageOverrides.external_id[imageId];
                    }
                    imageOverrides.external_id.i18n[language] = Object.assign({}, {[language]: imagesForLanguage});
                }

                for (const imageId of Object.keys(imageOverrides.external_id.i18n?.[language]) ?? []) {
                    if (imageId === "override_source") {
                        continue;
                    }

                    const override = imageOverrides.external_id.i18n[language][imageId];
                    const isOverrideSource = imageOverrides.external_id.override_source === true;
                    const isCurrentDefault = ["None", "Default", "Franchise", "Series", "Season", "Title by Prop Ep", "Title by Title Id"].includes(updatedImages.i18n[language]?.[imageId]?.scope);
                    const isCurrentExisting = updatedImages.i18n[language]?.[imageId]?.url;
                    console.log(`TimeMachineRundownCMSMasterDetailView.onUpdateImages: imageId: ${imageId}; url: ${override.url}; override source? ${isOverrideSource}; is current default? ${isCurrentDefault}, override: `, override);

                    let should_override = override && override.url && (isOverrideSource || isCurrentDefault || !isCurrentExisting);

                    if (should_override) {
                        updatedImages.i18n[language][imageId] = Object.assign({}, updatedImages.i18n[language][imageId], override, {override_source: imageOverrides.external_id.override_source, scope: "Show by External Id"});
                    }
                }
            }
            console.log("TimeMachineRundownCMSMasterDetailView.onUpdateImages: external_id image overrides: ", imageOverrides.external_id);
        }

        if (imageOverrides.hasOwnProperty("show")) {
            for (const language of languagesSupported) {
                if (!imageOverrides.show.hasOwnProperty("i18n")) {
                    const imagesForLanguage = {};
                    for (const imageId of imagesSupported) {
                        imagesForLanguage[imageId] = Object.assign({}, imageOverrides.show?.[imageId] ?? {});
                        delete imageOverrides.show[imageId];
                    }
                    imageOverrides.show.i18n[language] = Object.assign({}, {[language]: imagesForLanguage});
                }

                for (const imageId of Object.keys(imageOverrides.show.i18n?.[language]) ?? []) {
                    if (imageId === "override_source") {
                        continue;
                    }

                    const override = imageOverrides.show.i18n[language][imageId];
                    const isOverrideSource = imageOverrides.show.override_source === true;
                    const isCurrentDefault = ["None", "Default", "Franchise", "Series", "Season", "Title by Prop Ep", "Title by Title Id", "Show by External Id"].includes(updatedImages.i18n[language]?.[imageId]?.scope);
                    const isCurrentExisting = updatedImages.i18n[language]?.[imageId]?.url;
                    console.log(`TimeMachineRundownCMSMasterDetailView.onUpdateImages: imageId: ${imageId}; url: ${override.url}; override source? ${isOverrideSource}; is current default? ${isCurrentDefault}, override: `, override);

                    let should_override = override && override.url && (isOverrideSource || isCurrentDefault || !isCurrentExisting);

                    if (should_override) {
                        updatedImages.i18n[language][imageId] = Object.assign({}, updatedImages.i18n[language][imageId], override, {override_source: imageOverrides.show.override_source, scope: "Show by Show Id"});
                    }
                }
            }
            console.log("TimeMachineRundownCMSMasterDetailView.onUpdateImages: show image overrides: ", imageOverrides.show);
        }

        console.log("TimeMachineRundownCMSMasterDetailView.onUpdateImages: updated images from time machine show: ", updatedImages, "image overrides: ", imageOverrides);

        setImages(Object.assign({}, updatedImages));
    }, [JSON.stringify(selectedShow), JSON.stringify(instanceImageSpecs), JSON.stringify(imageOverrides), JSON.stringify(languagesSupported)]);

    useEffect(() => {
        console.log("TimeMachineRundownCMSMasterDetailView.onUpdateLanguagesSupported: ", languagesSupported);
    }, [JSON.stringify(languagesSupported)]);

    const loadOverrides = () => {
        if (Object.keys(selectedShow).length > 0) {
            setLoadingOverrides(true);
            const show_id = selectedShow.show_id;
            const external_id = selectedShow.external_id;
            const title_id = selectedShow.title_id;
            const prop_ep = selectedShow.prop_ep;
            const season_id = selectedShow.season_id;
            const series_id = selectedShow.series_id;
            const franchise_id = selectedShow.franchise_id;
            TimeMachineDataProvider.cmsGetOverrides(show_id, title_id, season_id, series_id, franchise_id).then(response => {
                console.log("TimeMachineRundownCMSMasterDetailView.loadOverrides: cms overrides for show: ", response);
                const formattedOverrides = {};

                if (!response.hasOwnProperty("error") || !response.hasOwnProperty("status")) {
                    for (const override of response) {
                        if (override.hasOwnProperty("franchise_id")) {
                            formattedOverrides.franchise = Object.assign({}, override.overrides);
                        } else if (override.hasOwnProperty("series_id")) {
                            formattedOverrides.series = Object.assign({}, override.overrides);
                        } else if (override.hasOwnProperty("season_id")) {
                            formattedOverrides.season = Object.assign({}, override.overrides);
                        } else if (override.hasOwnProperty("prop_ep")) {
                            formattedOverrides.prop_ep = Object.assign({}, override.overrides);
                        } else if (override.hasOwnProperty("title_id")) {
                            formattedOverrides.title = Object.assign({}, override.overrides);
                        } else if (override.hasOwnProperty("external_id")) {
                            formattedOverrides.external_id = Object.assign({}, override.overrides);
                        } else if (override.hasOwnProperty("show_id")) {
                            formattedOverrides.show = Object.assign({}, override.overrides);
                        }
                    }
                }
                console.log("TimeMachineRundownCMSMasterDetailView.loadOverrides: setting formatted overrides: ", formattedOverrides);
                setOverrides(formattedOverrides);
            }).finally(() => {
                setLoadingOverrides(false);
            });

            const updatedImages = {};
            const imageTemplate = {i18n: {}};
            for (const language of languagesSupported) {
                imageTemplate.i18n[language] = {};
                for (const imageId of Object.keys(imageSpecs)) {
                    imageTemplate.i18n[language][imageId] = Object.assign(
                        {},
                        imageSpecs[imageId],
                        {
                            imageId, url: "",
                            ratio: imageSpecs[imageId].ratio
                        }
                    );
                    imageTemplate.i18n[language][imageId].imageId = imageId;
                }
            }


            console.log("TimeMachineCMSMasterDetailView.loadOverrides: image template: ", imageTemplate);

            updatedImages.franchise = Object.assign({}, imageTemplate);
            updatedImages.series = Object.assign({}, imageTemplate);
            updatedImages.season = Object.assign({}, imageTemplate);
            updatedImages.prop_ep = Object.assign({}, imageTemplate);
            updatedImages.title = Object.assign({}, imageTemplate);
            updatedImages.external_id = Object.assign({}, imageTemplate);
            updatedImages.show = Object.assign({}, imageTemplate);

            setLoadingImages(true);

            TimeMachineDataProvider.cmsGetImages(franchise_id, series_id, season_id, prop_ep, title_id, external_id, show_id).then(response => {
                console.log("TimeMachineRundownCMSMasterDetailView.loadOverrides: get images response: ", response);
                for (const override of response) {
                    console.log("TimeMachineRundownCMSMasterDetailView.loadOverrides: formatting override: ", override);
                    if (!override.overrides.hasOwnProperty("i18n")) {
                        override.overrides.i18n = {"en-US": {}};
                        for (const imageId of Object.keys(imageSpecs)) {
                            override.overrides.i18n["en-US"][imageId] = override.overrides[imageId];
                            delete override.overrides[imageId];
                        }
                    }
                    const updatedOverrides = {i18n: {}};
                    for (const language of languagesSupported) {
                        updatedOverrides.i18n[language] = {};
                        if (!override.overrides.i18n.hasOwnProperty(language)) {
                            override.overrides.i18n[language] = {};
                        }

                        console.log(`TimeMachineRundownCMSMasterDetailView.loadOverrides: overrides for language ${language}: `, override.overrides.i18n[language]);

                        for (const imageId of Object.keys(imageSpecs)) {
                            updatedOverrides.i18n[language][imageId] = Object.assign({imageId}, imageSpecs[imageId], {url: override.overrides.i18n[language]?.[imageId] ?? ""});
                            updatedOverrides.override_source = override.hasOwnProperty("override_source") ? override.override_source : false;
                        }
                    }

                    if (override.hasOwnProperty("franchise_id")) {
                        console.log("TimeMachineRundownCMSMasterDetailView.loadOverrides: setting franchise overrides to: ", updatedOverrides);
                        updatedImages.franchise = Object.assign({}, updatedOverrides);
                    } else if (override.hasOwnProperty("series_id")) {
                        console.log("TimeMachineRundownCMSMasterDetailView.loadOverrides: setting series overrides to: ", updatedOverrides);
                        updatedImages.series = Object.assign({}, updatedOverrides);
                    } else if (override.hasOwnProperty("season_id")) {
                        updatedImages.season = Object.assign({}, updatedOverrides);
                    } else if (override.hasOwnProperty("prop_ep")) {
                        updatedImages.prop_ep = Object.assign({}, updatedOverrides);
                    } else if (override.hasOwnProperty("title_id")) {
                        console.log("TimeMachineRundownCMSMasterDetailView.loadOverrides: setting title overrides to: ", updatedOverrides);
                        updatedImages.title = Object.assign({}, updatedOverrides);
                    } else if (override.hasOwnProperty("external_id")) {
                        updatedImages.external_id = Object.assign({}, updatedOverrides);
                    } else if (override.hasOwnProperty("show_id")) {
                        console.log("TimeMachineRundownCMSMasterDetailView.loadOverrides: setting show overrides to: ", updatedOverrides);
                        updatedImages.show = Object.assign({}, updatedOverrides);
                    }
                }

                console.log("TimeMachineCMSMasterDetailView.loadOverrides: updated images: ", updatedImages);

                setImageOverrides(updatedImages);
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                setLoadingImages(false);
            });
        }
    };

    const performSearch = () => {
        const urlSearchParams = new URLSearchParams({
            ...selectedInstance && {instance: selectedInstance},
            ...selectedShowId && {showId: selectedShowId},
            ...selectedTitleId && {titleId: selectedTitleId},
            ...selectedPropEp && {propEp: selectedPropEp},
            ...selectedSeasonId && {seasonId: selectedSeasonId},
            ...selectedSeriesId && {seriesId: selectedSeriesId},
            ...selectedFranchiseId && {franchiseId: selectedFranchiseId},
            ...selectedExternalId && {externalId: selectedExternalId},
            ...selectedStartTime !== null && {
                startTime: typeof selectedStartTime == "number" ? selectedStartTime : Date.parse(selectedStartTime)
            },
            ...selectedEndTime !== null && {
                endTime: typeof selectedEndTime == "number" ? selectedEndTime : Date.parse(selectedEndTime)
            }
        });
        history.replace({pathname: location.pathname, search: urlSearchParams.toString()});

        setSearching(true);
        setSearchClicked(true);
        setSuccessMessage("");
        setErrorMessage("");
        setSelectedShow({});
        setShows([]);

        TimeMachineDataProvider.cmsGetShows(selectedInstance, selectedFranchiseId, selectedSeriesId, selectedSeasonId, selectedPropEp, selectedTitleId, selectedExternalId, selectedShowId, selectedStartTime, selectedEndTime).then(response => {
            console.log("TimeMachineRundownCMSMasterDetailView.performSearch: ", response);
            if (!response.hasOwnProperty("error")) {
                setShows(response);
            } else {
                console.error(response);
                setShows([]);
                setErrorMessage("There was an error retrieving shows from Time Machine for the given criteria.");
            }
        }).catch(error => {
            console.error(error);
            setErrorMessage("There was an error retrieving shows from Time Machine for the given criteria.");
        }).finally(() => {
            setSearching(false);
        });
    };

    const saveOverrides = overridesToSave => {
        setErrorMessage("");
        setSuccessMessage("");
        setSavingOverrides(true);
        TimeMachineDataProvider.cmsUpdateOverrides(overridesToSave).then(result => {
            console.log("TimeMachineRundownCMSMasterDetailView.saveOverrides: ", result);
            setOverrides(overridesToSave);
            setSuccessMessage("The overrides have been successfully updated.");
        }).catch(error => {
            setErrorMessage("There was an error saving the overrides.");
            console.error(error);
        }).finally(() => {
            loadOverrides();
            setSavingOverrides(false);
        });
    };

    const saveImageOverrides = async overrides => {
        console.log("TimeMachineRundownCMSMasterDetailView.saveImageOverrides: saving overrides: ", overrides)
        setErrorMessage("");
        setSuccessMessage("");
        setSavingImages(true);
        TimeMachineDataProvider.cmsAddImageToOverrides(overrides).then(response => {
            console.log("TimeMachineRundownCMSMasterDetailView.saveImageOverrides: response from saving overrides: ", response);
            loadOverrides();
        }).finally(() => {
            setSavingImages(false);
        });
    };

    const refresh = () => {
        loadOverrides();
    };

    const clearFilters = () => {
        setSelectedInstance("");
        setSelectedFranchiseId("");
        setSelectedSeriesId("");
        setSelectedSeasonId("");
        setSelectedPropEp("");
        setSelectedTitleId("");
        setSelectedShowId("");
        setSelectedExternalId("");
        setSelectedStartTime(INITIAL_START_MS);
        setSelectedEndTime(INITIAL_END_MS);
        setSuccessMessage("");
        setErrorMessage("");
    };

    return (
        Object.keys(selectedShow).length > 0 && editingImages ?
            <TimeMachineRundownCMSImageManagerView
                images={images}
                imageOverrides={imageOverrides}
                selectedShow={selectedShow}
                setEditingImages={setEditingImages}
                savingImages={savingImages}
                setSavingImages={setSavingImages}
                saveImageOverrides={saveImageOverrides}
                TimeMachineDataProvider={TimeMachineDataProvider}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                successMessage={successMessage}
                setSuccessMessage={setSuccessMessage}
                refresh={refresh}
                userCanEdit={userCanEdit}
                allPermissions={props.userPermissions}
                module={props.module}
                service={props.service}
                user={props.user}
                imageSpecs={instanceImageSpecs}
                imageNotFoundURL={imageNotFoundURL}
                languagesSupported={languagesSupported}
            /> :
            Object.keys(selectedShow).length > 0 && editingOverrides ?
            <TimeMachineRundownCMSOverrideEditorView
                selectedShow={selectedShow}
                TimeMachineDataProvider={TimeMachineDataProvider}
                cmsAttrsAllowed={cmsAttrsAllowed}
                overrides={overrides}
                editingOverrides={editingOverrides}
                setEditingOverrides={setEditingOverrides}
                saveOverrides={saveOverrides}
                savingOverrides={savingOverrides}
                setSavingOverrides={setSavingOverrides}
                successMessage={successMessage}
                errorMessage={errorMessage}
                userCanEdit={userCanEdit}
                allPermissions={props.userPermissions}
                module={props.module}
                service={props.service}
                user={props.user}
            /> :
            <TimeMachineRundownCMSSearchView
                metadata={metadata}
                setDoSearch={setDoSearch}
                shows={shows}
                setShows={setShows}
                instanceOptions={instanceOptions}
                setInstanceOptions={setInstanceOptions}
                selectedShow={selectedShow}
                setSelectedShow={setSelectedShow}
                setEditingImages={setEditingImages}
                TimeMachineDataProvider={TimeMachineDataProvider}
                overrides={overrides}
                selectedInstance={selectedInstance}
                setSelectedInstance={setSelectedInstance}
                selectedFranchiseId={selectedFranchiseId}
                setSelectedFranchiseId={setSelectedFranchiseId}
                selectedSeriesId={selectedSeriesId}
                setSelectedSeriesId={setSelectedSeriesId}
                selectedSeasonId={selectedSeasonId}
                setSelectedSeasonId={setSelectedSeasonId}
                selectedTitleId={selectedTitleId}
                setSelectedTitleId={setSelectedTitleId}
                selectedPropEp={selectedPropEp}
                setSelectedPropEp={setSelectedPropEp}
                selectedShowId={selectedShowId}
                setSelectedShowId={setSelectedShowId}
                selectedExternalId={selectedExternalId}
                setSelectedExternalId={setSelectedExternalId}
                selectedStartTime={selectedStartTime}
                setSelectedStartTime={setSelectedStartTime}
                selectedEndTime={selectedEndTime}
                setSelectedEndTime={setSelectedEndTime}
                editOverridesClicked={editingOverrides}
                setEditOverridesClicked={setEditingOverrides}
                editImagesClicked={editingImages}
                setEditImagesClicked={setEditingImages}
                clearFilters={clearFilters}
                searchClicked={searchClicked}
                setSearchClicked={setSearchClicked}
                searching={searching}
                loadingInstances={loadingInstances}
                loadingOverrides={loadingOverrides}
                savingOverrides={savingOverrides}
                setSavingOverrides={setSavingOverrides}
                loadingImages={loadingImages}
                images={images}
                imageSpecs={instanceImageSpecs}
                imageNotFoundURL={imageNotFoundURL}
                user={props.user}
                setInstanceImageSpecs={setInstanceImageSpecs}
                languagesSupported={languagesSupported}
                selectedRowIndex={selectedRowIndex}
                setSelectedRowIndex={setSelectedRowIndex}
            />
    );
};
