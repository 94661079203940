import React, {useMemo, useState} from "react";
import {Button, Form, Grid, Select, Input, Message, Icon} from "semantic-ui-react";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ReactTable from "react-table-v6";
import TimeMachineRundownCMSSubComponent from "./TimeMachineRundownCMSSubComponent";

const DATE_DISPLAY_OPTIONS = {year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric"};


export default function TimeMachineRundownCMSSearchView(props) {
    const [isSearchEnabled, setIsSearchEnabled] = useState(false);
    const [expanded, setExpanded] = useState({});

    useMemo(function updateIsSearchEnabled() {
        const userHasAspenPower = props.user.authz.includes("urn:all:aspen-power");
        setIsSearchEnabled(userHasAspenPower || props.selectedInstance.length > 1 || props.selectedSeriesId.length > 1 || props.selectedSeasonId.length > 1 || props.selectedTitleId.length > 1 || props.selectedShowId.length > 1);
    }, [props.selectedInstance, props.selectedFranchiseId, props.selectedSeriesId, props.selectedTitleId, props.selectedShowId]);

    const performSearch = () => {
        setExpanded({});
        props.setDoSearch(true);
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={3} className="masterContainer" style={{maxHeight: "95vh", overflowY: "auto", overflowX: "hidden"}}>
                    <Form widths={16}>
                        <Form.Field>
                            <Button disabled={!isSearchEnabled} fluid color="blue" onClick={performSearch}><Icon name="search"/>Search</Button>
                        </Form.Field>
                        <Form.Field>
                            <Button fluid color="black" onClick={props.clearFilters} icon><Icon name="trash"/>Clear Filters</Button>
                        </Form.Field>
                        <Form.Field
                            label="Instance"
                            control={Select}
                            search
                            allowAdditions
                            clearable
                            options={props.instanceOptions}
                            value={props.selectedInstance}
                            onChange={(event, {value}) => props.setSelectedInstance(value)}
                            onAddItem={(event, {value}) => props.setInstanceOptions(instanceOptions.concat({key: value, text: value, value}))}
                        />
                        <Form.Field
                            label="Show ID"
                            control={Input}
                            value={props.selectedShowId}
                            onChange={(event, {value}) => props.setSelectedShowId(value)}
                        />
                        <Form.Field
                            control={Input}
                            label="External ID"
                            value={props.selectedExternalId}
                            onChange={(event, {value}) => props.setSelectedExternalId(value)}
                        />
                        <Form.Field
                            label="Title ID"
                            control={Input}
                            value={props.selectedTitleId}
                            onChange={(event, {value}) => props.setSelectedTitleId(value)}
                        />
                        <Form.Field
                            label="PropEp"
                            control={Input}
                            value={props.selectedPropEp}
                            onChange={(event, {value}) => props.setSelectedPropEp(value)}
                        />
                        <Form.Field
                            label="Season ID"
                            control={Input}
                            value={props.selectedSeasonId}
                            onChange={(event, {value}) => props.setSelectedSeasonId(value)}
                        />
                        <Form.Field
                            label="Series ID"
                            control={Input}
                            value={props.selectedSeriesId}
                            onChange={(event, {value}) => props.setSelectedSeriesId(value)}
                        />
                        <Form.Field
                            label="Franchise ID"
                            control={Input}
                            value={props.selectedFranchiseId}
                            onChange={(event, {value}) => props.setSelectedFranchiseId(value)}
                        />
                        <Form.Field>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <label>Start Time</label>
                                <DateTimePicker
                                    inputVariant="outlined"
                                    fullWidth
                                    value={props.selectedStartTime}
                                    onChange={props.setSelectedStartTime}
                                />
                            </MuiPickersUtilsProvider>
                        </Form.Field>
                        <Form.Field width={16}>
                            <label>End Time</label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    inputVariant="outlined"
                                    fullWidth
                                    value={props.selectedEndTime}
                                    onChange={props.setSelectedEndTime}
                                />
                            </MuiPickersUtilsProvider>
                        </Form.Field>
                    </Form>
                </Grid.Column>
                <Grid.Column width={13} className="masterContainer" style={{maxHeight: "95vh", overflowY: "auto", overflowX: "auto"}}>
                    {
                        props.loadingInstances ?
                            <Message color="yellow" icon>
                                <Icon name="spinner" loading/>
                                <Message.Content>
                                    Loading instances...
                                </Message.Content>
                            </Message> :
                        !props.searchClicked ?
                            <Message color="yellow" icon="info">
                                <Message.Content>Please enter your search criteria and search to continue.</Message.Content>
                            </Message> :
                        props.searching ?
                            <Message color="yellow" icon>
                                <Icon name="spinner" loading />
                                <Message.Content>
                                    Searching...
                                </Message.Content>
                            </Message> :
                            <>
                                {
                                    props.errorMessage ?
                                        <Message error icon>
                                            <Icon name="warning" />
                                            <Message.Header>Error</Message.Header>
                                            <Message.Content>{props.errorMessage}</Message.Content>
                                        </Message> : ""
                                }
                                <ReactTable
                                    columns={[
                                        { accessor: '_id', show: false },
                                        { Header: "Instance", accessor: "feed_id", width: 150},
                                        { Header: "Title", accessor: "title"},
                                        { Header: "Season", accessor: "season_name"},
                                        { Header: "Series", accessor: "series_name"},
                                        { Header: "Franchise", accessor: "franchise_name"},
                                        {
                                            Header: "Show Timestamp",
                                            accessor: "show_timestamp",
                                            Cell: row => {
                                                return <span>{new Date(row.original.show_timestamp * 1000).toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}</span>;
                                            }
                                        }
                                    ]}
                                    data={props.shows}
                                    className="-striped -highlight"
                                    filterable
                                    defaultPageSize={100}
                                    defaultSorted={[
                                        {id: "show_timestamp", asc: true}
                                    ]}
                                    expanded={expanded}
                                    onExpandedChange={
                                        (expanded, index, event) => {
                                            console.log("onExpandedChange: ", expanded, index, event);
                                            const updatedExpanded = expanded.hasOwnProperty(index) ? expanded[index] : false;
                                            setExpanded({[index]: updatedExpanded});
                                        }
                                    }
                                    collapseOnDataChange={false}
                                    freezeWhenExpanded={true}
                                    SubComponent={
                                        row =>
                                            <TimeMachineRundownCMSSubComponent
                                                metadata={props.metadata}
                                                show={row.original}
                                                overrides={props.overrides}
                                                setEditOverridesClicked={props.setEditOverridesClicked}
                                                setEditImagesClicked={() => props.setEditImagesClicked(true)}
                                                TimeMachineDataProvider={props.TimeMachineDataProvider}
                                                loadingOverrides={props.loadingOverrides}
                                                loadingImages={props.loadingImages}
                                                images={props.images}
                                                imageSpecs={props.imageSpecs}
                                                imageNotFoundURL={props.imageNotFoundURL}
                                                languagesSupported={props.languagesSupported}
                                            />
                                    }
                                    getTrProps={(state, rowInfo, column, instance) => {
                                        return {
                                            onClick(event, handleOriginal) {
                                                props.setSelectedShow(rowInfo.original);
                                                props.setSelectedRowIndex(rowInfo.index);
                                            },
                                            style: {
                                                background: rowInfo && rowInfo.index === props.selectedRowIndex ? "rgba(65, 83, 175, .5)" : "",
                                            }
                                        }
                                    }}
                                />
                            </>
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
