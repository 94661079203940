import React, {useMemo, useState} from "react";
import {Icon, Message, Segment, Table} from "semantic-ui-react";
import TimeMachineRundownCMSOverrideEditorTableRow from "./TimeMachineRundownCMSOverrideEditorTableRow";
import ContingentButton from "../../ContingentButton";

export default function TimeMachineRundownCMSOverrideEditorTable(props) {
    const [overrideKeys, setOverrideKeys] = useState([]);

    useMemo(() => {
        const updatedOverrideKeys = [];

        for (const override of props.overrides) {
            updatedOverrideKeys.push(override.key);
        }

        setOverrideKeys(updatedOverrideKeys);
    }, [JSON.stringify(props.overrides)]);

    const updateKey = (index, updatedKey) => {
        if (index < props.overrides.length) {
            props.setOverrides(props.overrides.slice().map((override, i) => {
                return i !== index ? Object.assign({}, override) : {
                    key: updatedKey,
                    value: override.value
                }
            }));
        }
    }

    const updateValue = (index, updatedValue) => {
        if (index < props.overrides.length) {
            props.setOverrides(props.overrides.slice().map((override, i) => {
                return i !== index ? Object.assign({}, override) : {
                    key: override.key,
                    value: updatedValue
                }
            }));
        }
    }

    const removeOverride = index => {
        const updatedOverrides = props.overrides.slice();
        updatedOverrides.splice(index, 1);
        props.setOverrides(updatedOverrides);
    }

    return (
        <Segment basic fluid>
            <Table celled>
                {props.overrides.length > 0 ?
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Key</Table.HeaderCell>
                            <Table.HeaderCell>Value</Table.HeaderCell>
                            <Table.HeaderCell width={1}>&nbsp;</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header> : ""
                }
                <Table.Body>
                    {
                        props.overrides.length < 1 ?
                        <Table.Row>
                            <Table.Cell colSpan={3}>
                                <Message icon color="yellow">
                                    <Icon name="info" />
                                    <Message.Content>There are no {props.scope} overrides configured for this show.</Message.Content>
                                </Message>
                            </Table.Cell>
                        </Table.Row> :
                        props.overrides.map((override, index) =>
                            <TimeMachineRundownCMSOverrideEditorTableRow
                                key={`cms-row-${index}`}
                                index={index}
                                overrideKeys={overrideKeys}
                                cmsAttrsAllowed={props.cmsAttrsAllowed}
                                overrideKey={override.key}
                                overrideValue={override.value}
                                setOverrideKey={updatedKey => updateKey(index, updatedKey)}
                                setOverrideValue={updatedValue => updateValue(index, updatedValue)}
                                removeOverride={removeOverride}
                                userCanEdit={props.userCanEdit}
                                allPermissions={props.allPermissions}
                                module={props.module}
                                instance={props.instance}
                                service={props.service}
                                user={props.user}
                            />
                        )
                    }
                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.Cell colSpan="3">
                            <ContingentButton
                                fluid
                                onClick={() => props.setOverrides(props.overrides.concat({key: "", value: ""}))}
                                disabled={props.addOverrideDisabled}
                                allPermissions={props.allPermissions}
                                module={props.module}
                                service={props.service}
                                user={props.user}
                                scope={props.instance}
                                hideIfNotPermitted={true}
                            >Add Item</ContingentButton>
                        </Table.Cell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </Segment>
    );
};
